<template>
    <div>
        <v-container fluid class="login-container py-15">
            <v-layout row wrap class="">

                <v-flex xs12 class=" elevation-0 login-container d-flex align-center">
                    <v-container>
                        <v-layout row wrap justify-space-around>
                            <v-flex xs12 lg4 xl4>
                                <v-card width="100%" color="transparent" class="elevation-0 px-2">
                                    <v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-img :src="logobw" class="  rounded"
                                            style="border-radius: 8px!important;" max-width="60" height="60"></v-img>
                                    </v-card-title>
                                    <v-card-text class="mt-3 ">
                                        <form @submit.prevent="login">
                                            <v-text-field class="ltr-input" filled autocomplete="false"
                                                prepend-inner-icon="mdi-email" dense outlined placeholder="Email"
                                                v-model="user.email">
                                            </v-text-field>
                                            <v-text-field class="ltr-input" filled prepend-inner-icon="mdi-lock"
                                                v-model="user.password" dense outlined type="password"
                                                placeholder="Password">
                                            </v-text-field>
                                            <v-btn type="submit" block color="black" dark :loading="btn_loading">
                                                Login
                                            </v-btn>
                                        </form>
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
            </v-layout>
        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import {
        auth,
        signInWithEmailAndPassword,
        sendPasswordResetEmail
    } from '../firebase'
    export default {
        data() {
            return {
                logobw: require('./../assets/favicon.png'),
                // login_shape_right: require('./../assets/login-shape-right.svg'),
                user: {
                    email: '',
                    password: ''
                },
                snackbar: {
                    text: '',
                    color: '',
                    value: false
                },
                btn_loading: false
            }
        },
        methods: {
            login() {
                signInWithEmailAndPassword(auth, this.user.email, this.user.password)
                    .then((r) => {
                        localStorage.setItem('void_store_auth', 'auth')
                        this.$router.push('/')
                        this.btn_loading = false
                    })
                    .catch(err => {
                        localStorage.removeItem('void_store_auth')
                        this.btn_loading = false
                        this.snackbar = {
                            text: err.msg,
                            color: 'error',
                            value: true
                        }
                    })
            },
            passwordReset() {
                sendPasswordResetEmail(auth, this.user.email).then(() => {
                    this.snackbar = {
                        text: 'Email sent, please check your email',
                        color: 'success',
                        value: true
                    }
                })
            }
        }
    }
</script>
<style lang="css">
    .login-container {
        height: 95vh;
    }

    .ltr-input {
        direction: ltr;
    }
</style>